<template>
  <b-form @submit.prevent="OnSubmit">
    <b-form-group>
      <b-form-textarea
        v-model="form.Cim"
        placeholder="Cím"
        autocomplete="off"
        required
      ></b-form-textarea>
    </b-form-group>
    <b-form-group>
      <b-form-textarea
        v-model="form.Ismerteto"
        placeholder="Rövid ismertető"
        autocomplete="off"
        rows="5"
      ></b-form-textarea>
    </b-form-group>
    <b-form-group label="Cikk fejléc képe:">
      <k-file-select accept="image/*" @input="OnImageSelect($event[0])">
        <img
          class="file-select-img-preview border-radius-6"
          :src="form.Kep ? form.Kep : $placeholderImage"
          alt="Cikk képe"
        />

        <div class="blur-box absolute-bottom-right">
          <i class="fas fa-edit text-black mx-1"></i>
          <i
            class="fas fa-crop text-black mx-1"
            @click.prevent.stop="OpenImageCropperModal(form.Kep)"
          ></i>
        </div>
      </k-file-select>
    </b-form-group>
    <b-form-group label="Szervezet:">
      <k-basic-multi-select
        v-model="form.Szerzo"
        required
        :options="szervezetekFiltered"
        labelBy="Text"
      ></k-basic-multi-select>
    </b-form-group>
    <b-form-group label="Menü:">
      <k-basic-multi-select
        v-model="$v.form.Kategoriak.$model"
        :validator="$v.form.Kategoriak"
        :options="hirKategoriakSelect"
        group-values="Alkategoriak"
        :group-select="false"
        group-label="Text"
        labelBy="Text"
        multiple
      >
        <template slot="singleLabel" slot-scope="props">
          <div v-html="props.option.Text"></div>
        </template>
        <template slot="tag" slot-scope="props">
          <span class="multiselect__tag"
            ><span v-html="props.option.Text"></span>
            <i
              aria-hidden="true"
              tabindex="1"
              class="multiselect__tag-icon"
              @click="props.remove(props.option)"
            ></i
          ></span>
        </template>
        <template slot="option" slot-scope="props">
          <div v-html="props.option.$groupLabel || props.option.Text"></div>
        </template>
      </k-basic-multi-select>
    </b-form-group>
    <b-form-group label="Megjelenés dátuma:">
      <k-html-date-picker
        v-model="form.MegjelenesDatuma"
        required
        mode="dateTime"
      ></k-html-date-picker>
    </b-form-group>
    <b-form-group label="Státusz:">
      <k-basic-multi-select
        v-model="form.Statusz"
        required
        :options="statuszokSelect"
        labelBy="Text"
      ></k-basic-multi-select>
    </b-form-group>
    <!-- <b-form-group>
      <b-form-checkbox v-model="form.ArchivFl">
        Archív
      </b-form-checkbox>
    </b-form-group> -->
    <b-form-group>
      <b-form-checkbox v-model="form.VezerHirFl"> Vezércikk </b-form-checkbox>
    </b-form-group>
    <b-form-group>
      <b-form-checkbox v-model="form.KategoriaInditoFl">
        Kategória indító
      </b-form-checkbox>
    </b-form-group>
    <b-form-group>
      <b-form-checkbox v-model="form.CsakSzervezetnekFl">
        Csak a fent megadott szervezet tagjai számára
      </b-form-checkbox>
    </b-form-group>
    <b-form-group>
      <b-form-checkbox
        v-model="form.CsakBelepveFl"
        :disabled="form.CsakSzervezetnekFl"
      >
        Csak belépett olvasóknak
      </b-form-checkbox>
    </b-form-group>
    <b-form-group>
      <b-form-checkbox v-model="form.KepArnyekFl">
        Cikkben szereplő képek árnyékkal
      </b-form-checkbox>
    </b-form-group>
    <div class="d-flex justify-content-between">
      <div>
        <k-button
          variant="secondary"
          v-b-tooltip="'Előnézet'"
          class="mr-2"
          icon="fa-eye"
          :disabled="form.Id == 0"
          @click="OpenCikkElonezet"
        ></k-button>
        <k-cikk-megosztas :cikkId="form.Id"></k-cikk-megosztas>
      </div>
      <k-button
        type="submit"
        variant="primary"
        label="Mehet"
        icon="fa-save"
        :loading="saveLoading"
      ></k-button>
    </div>
  </b-form>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import { mapGetters } from 'vuex';
import { AppStoreTypes } from '../../store/modules/app';
import { GetBase64UrlFromFile, hidrateForm } from '../../utils/common';
import { NotificationFunctions } from '../../functions/NotificationFunctions';
import { hirService } from '../../services/HirService';
import moment from 'moment';
import required from 'vuelidate/src/validators/required';
import { IsVueFormValid } from '../../utils/vueUtils';
import { ModalFunctions } from '../../functions/ModalFunctions';
import { AppFunctions } from '../../functions/AppFunctions';
import { eventBus } from '../../main';

let defaultForm = () => {
  return {
    Id: null,
    Cim: '',
    Ismerteto: '',
    Kep: '',
    Szerzo: null,
    Kategoriak: [],
    Statusz: null,
    MegjelenesDatuma: null,
    ArchivFl: false,
    VezerHirFl: false,
    ProjektId: null,
    CsakSzervezetnekFl: false,
    CsakBelepveFl: false,
    KategoriaInditoFl: false,
    KepArnyekFl: true,
  };
};

export default {
  name: 'edit-article',
  data() {
    return {
      saveLoading: false,
      form: cloneDeep(defaultForm()),
    };
  },
  mounted() {},
  created() {
    eventBus.$on('update-image', this.UpdateImage);
  },
  beforeDestroy() {
    eventBus.$off('update-image', this.UpdateImage);
  },
  validations() {
    let validations = {
      form: {
        Kategoriak: { required },
      },
    };
    //if (this.isKategoriaKotelezo) {
    //  validations.form.Kategoriak = { required };
    //}
    return validations;
  },
  methods: {
    async OnImageSelect(e) {
      let kep = await GetBase64UrlFromFile(e);
      this.form.Kep = kep;
    },
    async OnSubmit() {
      if (!IsVueFormValid(this)) {
        console.log(this.$v);
        return;
      }
      this.saveLoading = true;
      try {
        var now = new Date();
        var megjelenesDatuma = new Date(moment(this.form.MegjelenesDatuma));

        let data = {
          ...cloneDeep(this.form),
          Tartalom: this.tartalom,
        };
        if (data.Kategoriak.length == 0) {
          data.Kategoriak.push({ Id: -1, Nev: 'Nincs megadva' });
        }
        if (data.Statusz.Id == 116) {
          data.ArchivFl = true;
        } else {
          data.ArchivFl = false;
        }

        if (megjelenesDatuma > now && data.Statusz.Id == 100) {
          data.Statusz.Id = 104;
          data.Statusz.Nev = 'Időzítve';
        }

        let result = await hirService.SaveHir({ data });
        NotificationFunctions.SuccessAlert('Hírek szerkesztése', 'Hír mentve');
        this.$emit('article-saved');
        if (!data.Id) {
          this.$router.replace({ name: 'Article', params: { id: result.Id } });
        }
      } catch (error) {
        NotificationFunctions.AjaxError({ error });
      }
      this.saveLoading = false;
    },
    OpenCikkElonezet() {
      ModalFunctions.OpenModal('k-cikk-elonezet-modal', {
        articleId: this.articleFromServer.Id,
      });
    },
    OpenImageCropperModal(kep) {
      let imgSrc = kep;
      ModalFunctions.OpenModal('k-image-cropper-modal', { kepUrl: imgSrc });
    },
    UpdateImage(kep) {
      this.form.Kep = kep.cropped;
    },
  },
  computed: {
    ...mapGetters({
      szervezetek: AppStoreTypes.szervezetek.getAll,
      hirKategoriak: AppStoreTypes.hirKategoriak.getAll,
      hirStatuszok: AppStoreTypes.hirStatuszok.getAll,
      user: AppStoreTypes.user.get,
    }),
    hirKategoriakSelect() {
      return this.hirKategoriak.map((m) => {
        return {
          Id: m.Id,
          Text: m.Nev,
          Alkategoriak: m.Kategoria.map((n) => {
            return {
              Id: n.Id,
              Text: n.Nev,
            };
          }),
        };
      });
    },
    szervezetekFiltered() {
      let szervezetId = this.user?.Szervezet?.Id;
      if (this.isSzerkeszto || szervezetId === undefined) {
        return this.szervezetek;
      }
      return this.szervezetek.filter((f) => f.Id == szervezetId);
    },
    isKategoriaKotelezo() {
      return !this.isSzerkeszto;
    },
    isSzerkeszto() {
      return AppFunctions.IsSzerkeszto(this.user);
    },
    statuszokSelect() {
      return this.hirStatuszok.map((m) => {
        return {
          Id: m.Id,
          Text: m.Nev,
        };
      });
    },
  },
  watch: {
    form: {
      handler: function (form) {
        this.$emit('form-changed');
      },
      deep: true,
    },
    articleFromServer: {
      handler: function (form) {
        if (form) {
          hidrateForm(this, this.form, form);
        } else {
          this.form = cloneDeep(defaultForm());
        }
      },
      deep: true,
      immediate: true,
    },
    'form.CsakSzervezetnekFl': function (val) {
      if (val == true) {
        this.form.CsakBelepveFl = true;
      }
    },
  },
  props: {
    articleFromServer: {
      type: Object,
    },
    tartalom: {
      type: String,
      required: true,
    },
  },
};
</script>
